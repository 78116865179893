body {
	padding-top: 80px;
	background-image: url('../img/Tech-BG.png');
	/* background-image: linear-gradient(to right, #a80017, #df0024); */
	background-repeat: no-repeat;
	background-size: cover;
}

.delegate {
	padding: 3rem;
	background-image: url('../img/globe-movo.png');
	background-repeat: no-repeat;
	background-position: center right;
	background-size: 650px;
}

.lightText {
	color: #f7f5f5;
}

.validator {
	font-weight: 500;
	color: #6c8ba1;
}

.modal-content {
	border-radius: 1.5rem !important;
}

.btn {
	padding: 8px 30px !important;
	border-radius: 25px !important;
	background-color: #ffffff !important;
	color: #181D31 !important;
	font-weight: 600 !important;
	font-size: 1.2rem !important;
}

.btn.delegate:hover {
	box-shadow: 0 4px 11px rgb(240 233 210 / 35%);
}

.btn-grad {
	background-image: linear-gradient(to right, #364651 0%, #A43931 51%, #dfb601 100%);
}

/* .btn-grad.withdraw {background-image: linear-gradient(to right, #A43931 0%, #dfb601  51%, #F7971E  100%)} */
.btn-grad {
	margin: 10px;
	padding: 15px 45px;
	text-align: center;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 0.08rem;
	transition: 0.5s;
	background-size: 200% auto;
	color: white;
	/* box-shadow: 0 0 5px #A43931; */
	border-radius: 10px;
	display: block;
	border: transparent;
}

.btn-grad:hover {
	background-position: right center;
	/* change the direction of the change here */
	color: #fff;
	text-decoration: none;
}

.accDetail {
	display: flex;
	z-index: 2;
	justify-content: space-between;
	padding: 1rem 2.5rem;
	color: cornsilk;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	background: url('../img/Tech-BG.png');
	background-repeat: no-repeat;
}

hr {
	margin-top: 2rem !important;
	margin-bottom: 2rem !important;
}

.card {
	border-radius: 2rem !important;
	/* padding-top: 1rem !important; */
	padding-bottom: 2rem !important;
}

.btn:focus {
	box-shadow: none;
	outline: none;
}

.stakeAmount {
	font-weight: 500;
}

.grayBackground {
	background-color: #d5dae0;
}

.bold {
	font-weight: 500;
}

.leaderboard {
	border-radius: 0.5rem;
}

.bold {
	font-weight: 500;
}

#movoLogo {
	position: relative;
	top: -0.15rem;
	width: 1.8rem;
	margin-left: 10px;
}

#addressConnected {
	/* background-color: #212529; */
	color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
	border-radius: 0.5rem;
	padding: 0.15rem 0.7rem 0.25rem;
	margin-right: 0.7rem;
}

.balance {
	color: #f7f5f5;
	padding: 5px 20px;
	border-radius: 25px;
	background-color: #181D31;
}

.stake {
	margin-top: 5rem;
}

.leader-board {
	margin-top: 5rem;
}